import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';

@Injectable({
  providedIn: 'root'
})
export class ManageBroadcastService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService) { }

  GetCommunityOwner(filter) {
    return this.http.post<any>(this.baseURL + 'ManageBroadcast/GetOwners', filter);
  }
  SendBroadCast(SelectedOwner,message)
  {
      const formData: FormData = new FormData();
      formData.append('message', JSON.stringify(message));
      formData.append('SelectedOwner',JSON.stringify(SelectedOwner));
      return this.http.post<Request>(this.baseURL + 'ManageBroadcast/SendBroadCast', formData);
  }
  getFilterDropDowns() {
    return this.http.get<any>(this.baseURL + 'ManageCommunityUnit/GetFilterDropDowns');
  
  }
  getSubCatgory(categoryId) {
    var filter= { 'categoryId': categoryId };
    return this.http.post<any>(this.baseURL + 'manageCategorySubcategory/GetSubCategory',filter);
  }
 
}
